import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function PageNotFound() {
  const navigate = useNavigate();
  return (
    <main className="main__container">
      <h1>
        <span>404</span>
      </h1>
      <div>
        <Button
          style={{ width: 200, margin:"200px" }}
          onClick={() => {
            navigate(-1);
          }}
        >
          Go back
        </Button>
      </div>
    </main>
  );
}
