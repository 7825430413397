import React from 'react'
import "./aboutus.css"
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { GET_Aboutus } from '../../graphql/query';
import i18next from 'i18next';
import { Skeleton } from '@mui/material';

const AboutUs = () => {
    const { t } = useTranslation();
    const currentLanguage = i18next.language;
    const { loading, error, data } = useQuery(GET_Aboutus);
    if (loading) return (
        <div className='container aboutusDetail'>
            <div className="row mb-5 mt-3">
                <div className='col'>
                    <div className="contactHeader" style={{ textAlign: "center" }}>
                        {t('aboutUs')}
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-lg-7 col-md-12 col-sm-12'>
                    <h2> {t('WhoisEkinoksEngineering')}</h2>
                    <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                    <Skeleton variant="text" sx={{ fontSize: '6rem' }} />
                    <Skeleton variant="text" sx={{ fontSize: '2rem' }} />

                </div>
                <div className='col-lg-5 col-md-12 col-sm-12'>
                    <div className='center'>
                        <Skeleton sx={{ height: 310 }} animation="wave" variant="rounded" />
                    </div>
                </div>
                <div className='col-lg-12 col-md-12 col-sm-12'>
                    <h2> {t('ourvision')}</h2>
                    <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                    <Skeleton variant="text" sx={{ fontSize: '6rem' }} />
                    <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
                </div>
            </div>
        </div>
    )
    if (error) console.log(error)
    const aboutusData = data.getAboutus
    return (
        <div className='container aboutusDetail'>
            <div className="row mb-5 mt-3">
                <div className='col'>
                    <div className="contactHeader" style={{ textAlign: "center" }}>
                        {t('aboutUs')}
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-lg-7 col-md-12 col-sm-12'>
                    <h2> {t('WhoisEkinoksEngineering')}</h2>
                    <p dangerouslySetInnerHTML={{ __html: aboutusData && aboutusData.aboutusRichText && currentLanguage !== "tr" ? currentLanguage === "en" ? aboutusData.aboutusRichTextEn : aboutusData.aboutusRichTextRu : aboutusData.aboutusRichText }}>
                    </p>
                </div>
                <div className='col-lg-5 col-md-12 col-sm-12'>
                    <div className='center'>
                        <img className='img-fluid mx-wimg' src={require("../../images/muhendis2.png")} alt='Ekinoks Mühendislik' />
                    </div>
                </div>
                <div className='col-lg-12 col-md-12 col-sm-12'>
                    <h2> {t('ourvision')}</h2>
                    <p dangerouslySetInnerHTML={{ __html: aboutusData && aboutusData.ourVisionRichText && currentLanguage !== "tr" ? currentLanguage === "en" ? aboutusData.ourVisionRichTextEn : aboutusData.ourVisionRichTextRu : aboutusData.ourVisionRichText }}></p>
                </div>
            </div>
        </div>
    )
}

export default AboutUs