import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaSnowflake, FaCloud, FaFireExtinguisher } from "react-icons/fa";
import "./services.css"
import { useTranslation } from 'react-i18next';

function Services() {
    const { t } = useTranslation();

    return (
        <Container className='servicesContainer'>
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="serviceTitle">
                            <h3>{t('services')}</h3>
                        </div>
                    </div>
                </div>
            </div>
            <Row>
                <Col xs={12} md={6} lg={4}   className='serviceArea'>
                    <div className='serviceIcon'>
                        <span>
                            <FaSnowflake />
                        </span>
                    </div>
                    <div className='serviceDetail media-body'>
                        <h3><a style={{textDecoration: "none", color: "#3b3b3b"}} href="#"  >{t('heatingandCooling')}</a></h3> <p>{t('heatingandCoolingDetail')}</p>
                    </div>
                </Col>
                <Col xs={12} md={6} lg={4}  className='serviceArea'>
                    <div className='serviceIcon'>
                        <span>
                            <FaCloud />
                        </span>
                    </div>
                    <div className='serviceDetail media-body'>
                        <h3><a style={{textDecoration: "none", color: "#3b3b3b"}} href="#">{t('ventilation')}</a></h3> <p>{t('ventilationDetail')}</p>
                    </div>
                </Col>
                <Col xs={12} md={6} lg={4}  className='serviceArea'>
                    <div className='serviceIcon'>
                        <span>
                            <FaFireExtinguisher />
                        </span>
                    </div>

                    <div className='serviceDetail media-body'>
                        <h3><a style={{textDecoration: "none", color: "#3b3b3b"}} href="#">{t('fire')}</a></h3> <p>{t('fireDetail')}</p>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default Services;