import "./App.css";
import AnimatedRoutes from "./components/animatedRoutes/AnimatedRoutes.jsx";
import AdminRoutes from "./components/adminRoutes/AdminRoutes";

export default function App() {
  return (
    <div className="App">

      <AnimatedRoutes />
      <AdminRoutes/>
      
    </div>
  );
}