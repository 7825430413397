import React from 'react'
import Dashboard from '../../components/admin/Dashboard'

const Admin = () => {
  return (
    <div>
        <Dashboard/>
    </div>
  )
}

export default Admin