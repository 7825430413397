import { useContext, useState } from "react";
import { AuthContext, login } from '../../context/authContext';
import { Alert, Button, Container, Stack, TextField } from "@mui/material";
import { useMutation } from "@apollo/client";
import { LOGIN_USER } from "../../graphql/mutation";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/loading/Loading";

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState([]);
    const context = useContext(AuthContext)
    const navigate = useNavigate();

    const [loginUser, { loading, error }] = useMutation(LOGIN_USER, {
        update(_, { data: { loginUser: userData } }) {
            context.login(userData)
            navigate("/admin")
        },
        onError({ graphQLErrors }) {
            setErrors(graphQLErrors)
        },
        variables: { loginInput: { email: email, password: password } },
    });

    const handleSubmit = (event) => {
        event.preventDefault();
        loginUser();
    };

    if (loading) return <Loading />;
    if (error) console.log(error);

    return (
        <Container style={{ marginTop: `100px` }} maxWidth="sm"/*  spacing={2} maxWidth="sm" */>
            <h3 style={{ textAlign: "left" }}>Giris yap</h3>
            <p style={{ textAlign: "left" }}>Bu sayfadan giris yaparak admin sayfasina giris saglayabilirsiniz.</p>
            <Stack spacing={2} paddingBottom={2} sx={{ width: "50ch" }} component="form" onSubmit={handleSubmit}>
                <TextField
                    label="Email"
                    name="email"
                    type="email"
                    onChange={(event) => setEmail(event.target.value)}
                />
                <TextField
                    label="Sifre"
                    name="password"
                    type="password"
                    onChange={(event) => setPassword(event.target.value)}
                />
                {
                    errors.length > 0 &&
                    errors.map((error, i) => (
                        <Alert severity="error" key={i}>
                            {error.message}
                        </Alert>
                    ))
                }
                <Button variant="contained" type="submit">Giris yap</Button>
            </Stack>
        </Container >
    )
}

export default Login;