import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { Nav, NavDropdown } from 'react-bootstrap';
import "./contactAlert.css"
import { Facebook, Twitter, LinkedIn, LocalPhoneSharp, Email, Translate } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

function ContactAlert() {

    const { i18n } = useTranslation();

    function changeLanguage(e) {
        i18n.changeLanguage(e);
        localStorage.setItem('lang', e);
    }

    return (
        <Navbar className="navbar-custom">
            <Container>
                <Navbar.Text className='navbarText' ><LocalPhoneSharp fontSize='small' />(0216) 225 0037  &nbsp; &nbsp; <Email fontSize='small' />info@ekinoksmekanik.com.tr</Navbar.Text>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse className="justify-content-end">
                    <Nav variant='piller'>
                        <Nav.Link href="https://www.facebook.com/profile.php?id=100095408853076" ><Facebook className='navbarSocials' fontSize='small' /></Nav.Link>
                        <Nav.Link href="https://twitter.com/EkinoksMekanik"><Twitter className='navbarSocials' fontSize='small' /></Nav.Link>
                        <Nav.Link href="https://www.linkedin.com/company/98386827/admin/feed/posts/" ><LinkedIn className='navbarSocials' fontSize='small' /></Nav.Link>
                        <NavDropdown title={<Translate fontSize='small' style={{ color: "#3b3b3b" }} />} id="basic-nav-dropdown">
                            <NavDropdown.Item onClick={()=>changeLanguage("tr")} value='tr'>TR</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item onClick={()=>changeLanguage("en")} value='en'>
                                EN
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item onClick={()=>changeLanguage("ru")} value='ru'>
                                RU
                            </NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default ContactAlert;