import { Image } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';

import "./carousels.css"
import { GET_CAROUSELS } from '../../graphql/query';
import { useQuery } from '@apollo/client';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@mui/material';

function Carousels() {
    const { t } = useTranslation();
    const currentLanguage = i18next.language;
    const { loading, error, data } = useQuery(GET_CAROUSELS);
    if (loading) return  < Skeleton animation="wave" variant="rectangular" width={'100vw'} height={'60vh'} />
    if (error) console.log(error)
    const carouselsData = data.getCarousels
    return (
        <Carousel>
            {carouselsData && carouselsData.map((data, i) => (
                <Carousel.Item className='carausel-custom' interval={5000}  key={i} >
                    <Image
                        className="d-block w-100 h-100 object-fit-cover border rounded"
                        src={data.carouselImg}
                        alt={t('home')}
                    />
                    <Carousel.Caption >
                        <h3 className='carauselHeader'>{currentLanguage !== "tr" ? currentLanguage === "en" ? data.carouselCaptionEn : data.carouselCaptionRu : data.carouselCaption}</h3>
                        <p className='carauselParagraph'>{currentLanguage !== "tr" ? currentLanguage === "en" ? data.carouselAltCaptionEn : data.carouselAltCaptionRu : data.carouselAltCaption}</p>
                    </Carousel.Caption>
                </Carousel.Item>
            ))}
        </Carousel>
    );
}

export default Carousels;