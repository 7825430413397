import React from 'react'
import "./servicesPage.css"
import SidebarWidget from '../../components/sidebar/SidebarWidget'
import { GET_DETAILSERVICES } from '../../graphql/query'
import { useQuery } from '@apollo/client'
import Loading from '../../components/loading/Loading'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const ServicesPage = ({ location }) => {
    const { loading, error, data } = useQuery(GET_DETAILSERVICES);
    const serviceLocation = location.replace("/services/", "");
    const { t } = useTranslation();
    const currentLanguage = i18next.language;

    if (loading) return <Loading />
    if (error) console.log(error)
    const detailServicesData = data.getDetailServices



    return (
        <div className='container'>
            <div className='row'>
                <div className="col-md-3 col-md-pull-9">
                    <SidebarWidget data={detailServicesData && detailServicesData} translate={t} />
                </div>
                <div className="col-md-9 col-md-push-3 ">
                    {detailServicesData && detailServicesData.map((data, i) => (
                        <React.Fragment key={i}>
                            {serviceLocation === data.id && (
                                <div className="detail">
                                    <img src={data.servicethumbnail}
                                        alt={data.name} />
                                    <div>
                                        <p style={{ textAlign: "start" }} dangerouslySetInnerHTML={{ __html: currentLanguage !== "tr" ? currentLanguage === "en" ? data.serviceRichTextEn : data.serviceRichTextRu : data.serviceRichText }}>
                                        </p>
                                    </div>
                                </div>
                            )}
                        </React.Fragment>
                    ))}
                </div>

            </div>
        </div>
    )
}

export default ServicesPage