import React from 'react'
import "./footer.css"
import { Facebook, LinkedIn, Twitter, YouTube } from '@mui/icons-material'
import { useTranslation } from 'react-i18next';
import { GET_FOOTER } from '../../graphql/query';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';

const Footer = () => {
    const { t } = useTranslation();
    const { loading, error, data } = useQuery(GET_FOOTER);
    if (loading) return
    if (error) console.log(error)
    const footerData = data.getFooter
    return (
        <div className="col-md-12" >
            <footer className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 m-b-30">
                            <div className="footer-title m-t-5 m-b-20 p-b-8">
                                {t('contact')}
                            </div>
                            <div className="footer-links">
                                {(footerData && footerData.footerContactUs) && footerData.footerContactUs.map((data, i) => (
                                    <span key={i}>
                                        {
                                            data.includes("@") ? (
                                                <a href={`mailto:${data}`}>
                                                    {data}
                                                </a>
                                            ) : ("0" + parseInt(data, 10).toString() === data && data.startsWith("0") && data.length === 11) && (
                                                <a href={`tel:+${data}`}>
                                                    {`${data[0]} (${data[1]}${data[2]}${data[3]}) ${data[4]}${data[5]}${data[6]} ${data[7]}${data[8]}${data[9]}${data[10]}`}
                                                </a>
                                            )
                                        }
                                    </span>
                                ))}
                            </div>
                        </div>
                        <div className="col-md-4 m-b-30">
                            <div className="footer-title m-t-5 m-b-20 p-b-8">
                                {t('quickLinks')}
                            </div>
                            <div className="footer-links">
                                <Link to={"/"}>
                                    {t('home')}
                                </Link>
                                <Link to={"/allProjects"}>
                                    {t('projects')}
                                </Link>
                                {/*    <Link to={"/allProjects"}>
                                            {t('references')}
                                        </Link> */}
                                <Link to={"/contactus"}>
                                    {t('contact')}
                                </Link>
                            </div>

                        </div>
                        <div className="col-md-4 m-b-30">
                            <div className="footer-title m-t-5 m-b-20 p-b-8">
                                {t('social')}
                            </div>
                            <div className="footer-links">
                                <a href="https://www.facebook.com/profile.php?id=100095408853076">
                                    <Facebook />
                                </a>
                                <a href="https://twitter.com/EkinoksMekanik">
                                    <Twitter />
                                </a>
                                <a href="https://www.linkedin.com/company/98386827/admin/feed/posts/">
                                    <LinkedIn />
                                </a>
                                <a href="https://www.youtube.com/channel/UCdmqbYbJw_4RCTtUg2qxIaw">
                                    <YouTube />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <div className="footer-bottom">
                {t('allRightsReserved')} ©EKINOKS 2023
            </div>
        </div>
    )
}

export default Footer