import { Route, Routes, useLocation } from 'react-router-dom';
/* import Register from '../../pages/register/Register.jsx';
 */
import Login from '../../pages/login/Login.jsx';
import Admin from '../../pages/admin/Admin.jsx';
import { useContext } from 'react';
import { AuthContext } from '../../context/authContext.js';

const AdminRoutes = () => {
    const { user, logout } = useContext(AuthContext)
    const location = useLocation();
    return (
        <>
            <Routes location={location} key={location.pathname}>
                {user ? (
                    <Route path="admin" element={<Admin />} />
                ) :
                    <Route path="admin" element={<Login />} />
                }
                {/*<Route path="admin/register" element={<Register />} />*/}
                <Route path="admin/login" element={<Login />} />
                {/*                 <Route path="admin/product" element={<AddProduct/>} />
 */}            </Routes>
        </>
    )
}

export default AdminRoutes