import React, { useEffect, useState } from "react";

import { BsFillTrashFill, BsFillPencilFill } from "react-icons/bs";

import "./Table.css";
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Grid, Paper, Slide, TextField } from "@mui/material";
import { Send } from "@mui/icons-material";
import { Container } from "react-bootstrap";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { GET_PROJECT } from "../../../graphql/query";
import Loading from "../../loading/Loading";
import { UPDATE_PROJECT } from "../../../graphql/mutation";
import { toast } from "react-toastify";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const Table = ({ var1, var2, var3, rows, deleteRow }) => {
  const [getUser, { loading, error, data }] = useLazyQuery(GET_PROJECT);
  const [updateProject, { loading: loadingProject }] = useMutation(UPDATE_PROJECT);

  const [open, setOpen] = useState(false);
  const [values, setValues] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [imageUrls, setImageUrls] = useState([]);
  useEffect(() => {
    setImageUrl(data && data.getProject && data.getProject.thumbnail)
    setImageUrls(data && data.getProject && data.getProject.images)
    setValues(data && data.getProject)
  }, [data])

  const handleClickOpen = (rowId) => {
    setOpen(true);
    getUser({ variables: { ProjectID: rowId } })
  };
  const handleClose = () => {
    setOpen(false);
    setValues(data && data.getProject)
  };
  const handleChange = (event) => {
    if (event.target.value == "true") {
      setValues({ ...values, [event.target.name]: true });
    } else if (event.target.value == "false") {
      setValues({ ...values, [event.target.name]: false });
    } else {
      setValues({ ...values, [event.target.name]: event.target.value });
    }
  }




  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setImageUrl(reader.result);
      setValues({ ...values, thumbnail: reader.result });
    };

    reader.readAsDataURL(file);
  };

  const handleFileDelete = (blob) => {
    setValues({ images: values.images.filter(img => img !== blob) })
  }

  const handleFileUploads = (event) => {
    const newArr = []
    for (let i = 0; i < (event.target.files).length; i++) {
      const file = event.target.files[i];
      const reader = new FileReader();

      reader.onloadend = () => {
        newArr.push(reader.result)
        setImageUrls(oldArray => [...oldArray, reader.result]);
      };

      reader.readAsDataURL(file);
    }
    for (let index = 0; index < values.images.length; index++) {
      newArr.push(values.images[index])
    }

    setValues({ ...values, images: newArr });
  };


  const notify = () => {
    toast.info("Proje Güncellendi!", {
      position: toast.POSITION.TOP_RIGHT
    });
  };

  const handleUpdateProject = (id) => {
    const projectInput = values
    delete projectInput.date;
    delete projectInput.id;
    delete projectInput.__typename;
    updateProject({ variables: { projectInput: projectInput, id: id } });
    handleClose();
    notify();
  };

  if (loading) return <Loading />;
  if (error) console.log(error);

  return (
    <div className="table-wrapper">
      <table className="table">
        <thead>
          <tr>
            <th>{var1}</th>
            <th className="expand">{var2}</th>
            <th>{var3}</th>
            <th>Aksiyon</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row) => {
            return (
              <tr key={row.id}>
                <td>{row.name}</td>
                <td className="expand">{row.clientCompany}</td>
                <td>
                  <span className={`label label-${String(row.status)}`}>
                    {row.status !== undefined && (row.status == true ? "bitmis" : "devam")}
                  </span>
                  {row.projectType && row.projectType}
                </td>
                <td className="fit">
                  <span className="actions">
                    <Button variant="outlined" onClick={() => { if (window.confirm("Projeyi silmek istediğinizden emin misiniz?")) deleteRow(row.id) }}>
                      <BsFillTrashFill
                        className="delete-btn"
                      />
                    </Button>
                    <Button variant="outlined" onClick={() => handleClickOpen(row.id)}>
                      <BsFillPencilFill
                        className="edit-btn"
                      />
                    </Button>
                    <Dialog
                      open={open}
                      TransitionComponent={Transition}
                      keepMounted
                      onClose={handleClose}
                      aria-describedby="alert-dialog-slide-description"
                    >
                      <DialogTitle>{"Proje Güncelleme Ekranı"}</DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">

                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Container >
                          <Grid container spacing={3}>
                            <Grid item xs={12} md={12} lg={12}>
                              <Paper
                                sx={{
                                  p: 2,
                                  display: 'flex',
                                  flexDirection: 'column',
                                  height: "100%",
                                }}
                              >
                                <Box
                                  sx={{
                                    '& > :not(style)': { m: 2, width: '25   ch' },
                                  }}
                                  noValidate
                                  autoComplete="off"
                                >
                                  <TextField name="name" defaultValue={data && data.getProject.name} onChange={handleChange} label="İsim" variant="outlined" required />
                                  <TextField name="nameEn" defaultValue={data && data.getProject.nameEn} onChange={handleChange} label="İsim(İngilizce)" variant="outlined" required />
                                  <TextField name="nameRu" defaultValue={data && data.getProject.nameRu} onChange={handleChange} label="İsim(Rusça)" variant="outlined" required />
                                  <TextField
                                    label="Detay"
                                    multiline
                                    rows={4}
                                    required
                                    name="detail" onChange={handleChange}
                                    defaultValue={data && data.getProject.detail}
                                  />
                                  <TextField
                                    label="Detay(İngilizce)"
                                    multiline
                                    rows={4}
                                    required
                                    name="detailEn" onChange={handleChange}
                                    defaultValue={data && data.getProject.detailEn}
                                  />
                                  <TextField
                                    label="Detay(Rusça)"
                                    multiline
                                    rows={4}
                                    required
                                    name="detailRu" onChange={handleChange}
                                    defaultValue={data && data.getProject.detailRu}
                                  />
                                  <TextField name="location" onChange={handleChange} label="Yer" variant="outlined" required defaultValue={data && data.getProject.location} />
                                  <TextField name="locationEn" onChange={handleChange} label="Yer(İngilizce)" variant="outlined" required defaultValue={data && data.getProject.locationEn} />
                                  <TextField name="locationRu" onChange={handleChange} label="Yer(Rusça)" variant="outlined" required defaultValue={data && data.getProject.locationRu} />
                                  <TextField name="relevantEntity" onChange={handleChange} label="İlgili Kurum" variant="outlined" required defaultValue={data && data.getProject.relevantEntity} />
                                  <TextField name="relevantEntityEn" onChange={handleChange} label="İlgili Kurum(İngilizce)" variant="outlined" required defaultValue={data && data.getProject.relevantEntityEn} />
                                  <TextField name="relevantEntityRu" onChange={handleChange} label="İlgili Kurum(Rusça)" variant="outlined" required defaultValue={data && data.getProject.relevantEntityRu} />
                                  <TextField name="projectScope" onChange={handleChange} label="Proje Kapsamı" variant="outlined" required defaultValue={data && data.getProject.projectScope} />
                                  <TextField name="projectScopeEn" onChange={handleChange} label="Proje Kapsamı(İngilizce)" variant="outlined" required defaultValue={data && data.getProject.projectScopeEn} />
                                  <TextField name="projectScopeRu" onChange={handleChange} label="Proje Kapsamı(Rusça)" variant="outlined" required defaultValue={data && data.getProject.projectScopeRu} />
                                  <TextField name="dateOfProject" onChange={handleChange} label="Tarih" variant="outlined" required defaultValue={data && data.getProject.dateOfProject} />
                                  <TextField name="clientCompany" onChange={handleChange} label="İş Veren Firma" variant="outlined" required defaultValue={data && data.getProject.clientCompany} />
                                  <TextField name="clientCompanyEn" onChange={handleChange} label="İş Veren Firma(İngilizce)" variant="outlined" required defaultValue={data && data.getProject.clientCompanyEn} />
                                  <TextField name="clientCompanyRu" onChange={handleChange} label="İş Veren Firma(Rusça)" variant="outlined" required defaultValue={data && data.getProject.clientCompanyRu} />
                                  <br />
                                  <FormControlLabel control={<Checkbox defaultChecked={data && data.getProject.status} />} onChange={handleChange} name="status" value={(values && values.status) ? (values.status == true) ? false : true : true} label="Tamamlandi mi?" />
                                  <br />
                                  <label htmlFor="upload-image">
                                    <Button variant="contained" component="span">
                                      Vitrin Resimi Yükle
                                    </Button>
                                    <input
                                      id="upload-image"
                                      hidden
                                      accept="image/*"
                                      type="file"
                                      onChange={handleFileUpload}
                                      required
                                    />
                                  </label>
                                  {imageUrl && <img src={imageUrl} alt="fotograf" height="300" />}
                                  <br />
                                  <label htmlFor="upload-images">
                                    <Button variant="contained" component="span">
                                      Kalan Resimleri Yükle
                                    </Button>
                                    <input
                                      id="upload-images"
                                      hidden
                                      accept="image/*"
                                      type="file" multiple name="files[]"
                                      onChange={handleFileUploads}
                                      required
                                    />
                                  </label>
                                  {imageUrls && imageUrls.map((imgurl, i) => (
                                    <div style={{ position: "relative" }} key={i}>
                                      <span onClick={() => handleFileDelete(imgurl)} style={{ position: "absolute", backgroundColor: "black", cursor: "pointer", color: "white", zIndex: "9999999", width: "20px", height: "20px", borderRadius: "50%", textAlign: "center" }}>X</span>
                                      <img src={imgurl} alt="Proje fotoğrafı" height="300" width="100%" style={{ objectFit: "contain", position: "relative" }} />
                                    </div>
                                  ))}
                                  <br />
                                  <Button disabled={loadingProject} variant="contained" endIcon={<Send />} onClick={() => handleUpdateProject(data.getProject.id)}>Proje Güncelle</Button>
                                  <Button onClick={handleClose}>İptal</Button>
                                </Box>
                              </Paper>
                            </Grid>
                          </Grid>
                        </Container>
                      </DialogActions>
                    </Dialog>
                  </span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};