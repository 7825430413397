import { gql } from "@apollo/client";

export const GET_PROJECTS = gql`
    query GetProjects {
        getProjects {
            id
            name
            nameEn
            nameRu
            thumbnail
            status
        }
    }
`;

export const GET_PROJECTS_LIST = gql`
    query GetProjects {
    getProjects {
        id
        name
        nameEn
        nameRu
        dateOfProject
        thumbnail
        clientCompany
        status
    }
    }
`;

export const GET_PROJECTS_TRUE = gql`
    query GetProjectsStatusTrue {
        getProjectsStatusTrue {
            id
            name
            nameEn
            nameRu
            thumbnail
        }
    }
`;

export const GET_PROJECTS_FALSE = gql`
    query GetProjectsStatusFalse {
        getProjectsStatusFalse {
            id
            name
            nameEn
            nameRu
            thumbnail
        }
    }
`;


export const GET_REFERENCES = gql`
    query GetReferences {
        getReferences {
            id
            name
            nameEn
            nameRu
            date
            location
            locationEn
            locationRu
            relevantEntity
            relevantEntityEn
            relevantEntityRu
            projectScope
            projectScopeEn
            projectScopeRu
            dateOfProject
            clientCompany
            clientCompanyEn
            clientCompanyRu
            projectType
        }
}
`;



export const GET_PROJECT = gql`
query GetProject($ProjectID: ID!) {
  getProject(id: $ProjectID) {
    id
    name
    nameEn
    nameRu
    detail
    detailEn
    detailRu
    thumbnail
    images
    date
    location
    locationEn
    locationRu
    relevantEntity
    relevantEntityEn
    relevantEntityRu
    projectScope
    projectScopeEn
    projectScopeRu
    dateOfProject
    clientCompany
    clientCompanyEn
    clientCompanyRu,
    status
  }
}
`;




export const GET_FOOTER = gql`
    query GetFooter {
        getFooter {
            footerRichText
            footerRichTextEn
            footerRichTextRu
            footerContactUs
        }
    }
`;

export const GET_Aboutus = gql`
   query GetAboutus {
        getAboutus {
            aboutusRichText
            aboutusRichTextEn
            aboutusRichTextRu
            ourVisionRichText
            ourVisionRichTextEn
            ourVisionRichTextRu
        }
    }
`;

export const GET_MEDIA = gql`
    query GetMedia {
        getMedia {
            id
            media
            mediaTitle
        }
    }
`;

export const GET_CAROUSELS = gql`
   query GetCarousels {
        getCarousels {
            id
            carouselCaption
            carouselCaptionEn
            carouselCaptionRu
            carouselAltCaption
            carouselAltCaptionEn
            carouselAltCaptionRu
            carouselImg
        }
    }
`;

export const GET_DETAILSERVICES = gql`
   query GetDetailServices {
        getDetailServices {
            id
            serviceCaption
            serviceCaptionEn
            serviceCaptionRu
            servicethumbnail
            serviceRichText
            serviceRichTextEn
            serviceRichTextRu
        }
    }
`;

export const GET_DETAILSERVICES_ID = gql`
   query GetDetailServices {
        getDetailServices {
            id
        }
    }
`;

export const GET_FAVORITEPROJECTS = gql`
  query GetFavoriteProjects {
        getFavoriteProjects {
            id
            projectId
            name
            nameEn
            nameRu
            thumbnail
        }
    }
`;