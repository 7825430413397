import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_PROJECTS_FALSE } from '../../graphql/query';
import Loading from '../../components/loading/Loading';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useState } from 'react';

export default function GoingOnProjects() {
    const { t } = useTranslation();
    const { loading, error, data } = useQuery(GET_PROJECTS_FALSE);
    const currentLanguage = i18next.language;
    const [alignment, setAlignment] = useState('web');

    if (loading) return <Loading />;
    if (error) console.log(error);
    const windowCalculator = window.screen.width > 760 ? (window.screen.width < 1200 ? 2 : 3) : 1
    const { getProjectsStatusFalse } = data

    const handleChange = (event, newAlignment) => {
        setAlignment(newAlignment);
    };

    return (
        <div className='container' >
            <ToggleButtonGroup
                style={{ display: "flex" }}
                color="warning"
                value={alignment}
                exclusive
                onChange={handleChange}
                aria-label="Platform"
            >
                <Link to={"/allprojects"}><ToggleButton style={{ padding: "5px", }} value="web">{t("projects")}</ToggleButton></Link>
                <Link to={"/completedProjects"}><ToggleButton style={{ padding: "5px" }} value="android">{t("completedProjects")}</ToggleButton></Link>
                <Link to={"/goingonprojects"}><ToggleButton style={{ padding: "5px", fontWeight: "400" }} value="ios">{t("goingOnProjects")}</ToggleButton></Link>
            </ToggleButtonGroup>
            <ImageList className='pb-5 pt-1' style={{ gridTemplateColumns: `repeat(${windowCalculator}, 1fr)`, gap: "22px", overflowY: "visible" }}>
                <ImageListItem key="Subheader" cols={windowCalculator}>
                    <ListSubheader component="div" style={{ backgroundColor: "#393E46", color: "#EEEEEE", fontSize: "20px", fontWeight: "600", textTransform: "uppercase", borderRadius: "10px" }}>{t("goingOnProjects")}</ListSubheader>
                </ImageListItem>
                {getProjectsStatusFalse.map((item) => (
                    <ImageListItem key={item.id} style={{ height: "200" }}>
                        <figure className="snip0060">
                            <img
                                src={item.thumbnail}
                                srcSet={item.thumbnail}
                                alt={currentLanguage !== "tr" ? currentLanguage === "en" ? item.nameEn : item.nameRu : item.name}
                                loading="lazy"
                            />
                            <figcaption>
                                <div ><i style={{ backgroundColor: "transparent" }}></i></div>

                                <div>
                                    <p>{currentLanguage !== "tr" ? currentLanguage === "en" ? item.nameEn : item.nameRu : item.name}</p>
                                </div>
                                <Link to={`/projects/${item.id}`}></Link>
                            </figcaption>
                        </figure>

                        <ImageListItemBar
                            title={currentLanguage !== "tr" ? currentLanguage === "en" ? item.nameEn : item.nameRu : item.name}
                            /* subtitle={item.author} */
                            style={{ background: "rgba(0, 0, 0, 0.7)", fontWeight: "600", marginBottom: "16px" }}
                            actionIcon={
                                <IconButton
                                    sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                    aria-label={`info about ${currentLanguage !== "tr" ? currentLanguage === "en" ? item.nameEn : item.nameRu : item.name}`}
                                    title={`${currentLanguage !== "tr" ? currentLanguage === "en" ? item.nameEn : item.nameRu : item.name} ${t("clickToSeeOurReferences")} `}
                                >
                                    <InfoIcon />
                                </IconButton>
                            }
                        />
                    </ImageListItem>
                ))}
            </ImageList>
        </div>
    );
}
