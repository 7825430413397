import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
    fallbackLng: 'tr',
    lng: localStorage.getItem("lang"),
    detection: {
        order: ["localStorage", "lang"],
        lookupQuerystring: "lang",
        lookupLocalStorage: "lang",
        caches: ["localStorage"],
    },
    resources: {
        en: {
            translations: require('./locales/en/translations.json')
        },
        tr: {
            translations: require('./locales/tr/translations.json')
        },
        ru: {
            translations: require('./locales/ru/translations.json')
        }
    },
    ns: ['translations'],
    defaultNS: 'translations'
});

i18n.languages = ['en', 'tr', 'ru'];

export default i18n;