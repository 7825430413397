import { gql } from "@apollo/client";

export const LOGIN_USER = gql`
    mutation loginUser($loginInput: LoginInput!) {
        loginUser(loginInput: $loginInput) {
            token
            username
            email
        }
    }
`;

export const CREATE_PROJECT = gql`
    mutation createProject($body: Input!) {
        createProject(body: $body) {
            id
        }
    }
`;

export const DELETE_PROJECT = gql`
    mutation deleteProject($id: ID!) {
        deleteProject(ID: $id)
    }
`;

export const CREATE_MEDIA = gql`
    mutation Mutation($bodyMedia: InputMedia) {
  createMedia(bodyMedia: $bodyMedia) {
    id
    media
    mediaTitle
  }
}
`;

export const DELETE_MEDIA = gql`
  mutation DeleteMedia($id: ID!) {
  deleteMedia(ID: $id)
}
`;

export const UPDATE_ABOUTUS = gql`
    mutation UpdateAboutus($aboutusInput: aboutusInput) {
  updateAboutus(aboutusInput: $aboutusInput)
}
`;

export const CREATE_CAROUSEL = gql`
    mutation CreateCarousel($bodyCarousel: InputCarousel!) {
  createCarousel(bodyCarousel: $bodyCarousel) {
    id
    carouselCaption
    carouselCaptionEn
    carouselCaptionRu
    carouselAltCaption
    carouselAltCaptionEn
    carouselAltCaptionRu
    carouselImg
  }
}
`;

export const DELETE_CAROUSEL = gql`
    mutation DeleteCarousel($id: ID!) {
  deleteCarousel(ID: $id)
}
`;

export const UPDATE_FOOTER = gql`
   mutation UpdateFooter($footerInput: footerInput) {
  updateFooter(footerInput: $footerInput)
}
`;

export const CREATE_FAVORITE_PROJECT = gql`
mutation CreateFavoriteProjects($bodyFavoriteProjects: InputFavoriteProjects!) {
  createFavoriteProjects(bodyFavoriteProjects: $bodyFavoriteProjects) {
    id
    name
    nameEn
    nameRu
    projectId
    thumbnail
  }
}
`;

export const DELETE_FAVORITE_PROJECT = gql`
mutation DeleteFavoriteProjects($id: ID!) {
  deleteFavoriteProjects(ID: $id)
}
`;

export const CREATE_DETAIL_SERVİCE = gql`
mutation CreateDetailServices($bodyDetailServices: InputDetailServices!) {
  createDetailServices(bodyDetailServices: $bodyDetailServices) {
    id
    serviceCaption
    serviceCaptionEn
    serviceCaptionRu
    servicethumbnail
    serviceRichText
    serviceRichTextEn
    serviceRichTextRu
  }
}
`;

export const DELETE_DETAIL_SERVİCE = gql`
mutation DeleteDetailServices($id: ID!) {
  deleteDetailServices(ID: $id)
}
`;

export const UPDATE_PROJECT = gql`
mutation Mutation($id: ID!, $projectInput: projectInput) {
  updateProject(ID: $id, projectInput: $projectInput)
}
`;
