import React from 'react'
import { Link } from 'react-router-dom'
import i18next from "i18next";

const SidebarWidget = ({ data, translate }) => {
    const currentLanguage = i18next.language;
    return (
        <ul className="widget-menu">
            <li ><b>{translate("services")}</b></li>
            {data && data.map((d, i) => (
                <li key={i}>
                    <Link to={`/services/${d.id}`} >{currentLanguage !== "tr" ? currentLanguage === "en" ? d.serviceCaptionEn : d.serviceCaptionRu : d.serviceCaption} </Link>
                </li>
            ))}
        </ul >
    )
}

export default SidebarWidget